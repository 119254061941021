<template>
  <list :dataSet="dataSet"></list>
</template>



<script>

import List from "@/views/Insights/List/List";

export default {
  name: "Books",
  components: {List},
  data(){
    return{
      title:'Books',
      dataSet:'books'
    }
  },
  mounted() {
    document.title = "Books";
  }
}
</script>

<style scoped>

</style>